<template>
	<div id="admin" v-if="is_staff === 'true'">
		<div id="header">
      <v-toolbar  color="light-blue darken-2" elevation="1" dark class="rounded mx-auto" >
        <v-toolbar-title><h3 class="font-weight-light">Panel de administración</h3></v-toolbar-title>
      </v-toolbar>
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col cols=6>
          <v-card flat height="100%">
            <v-card-title >
              <h3 class="font-weight-light">Registrar Usuario</h3>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="register"  class="mt-4" ref='form'>
                <v-text-field  v-model='email' light prepend-icon='mdi-email' label='Email' type='email'  :rules="[v => !!v || 'Ingrese un Email'] " required>
                </v-text-field>

                <v-select
                  :items="tipos_usuarios"
                  prepend-icon='mdi-account-box'
                  v-model="tipo_usuario"
                  label="Tipo de usuario"
                  :rules="[v => !!v || 'Seleccione un tipo']"
                  v-on:change="cambiarTipo"
                  required
                ></v-select>

                 <v-text-field
                  :value="username"
                  v-model="username"                  
                  label="Nombre de usuario"
                  readonly
                  prepend-icon='mdi-account'
                  v-if="tipo_usuario !== null"
                ></v-text-field>
                <v-text-field  v-model='password'  light prepend-icon='mdi-lock' label='Contraseña' type='password' :rules="[v => !!v || 'Ingrese una contraseña'] " required>
                </v-text-field>
               
                <br>
                <div v-if="register_succesful">
                  <v-alert type="success" :value="register_succesful" class="text-left" transition="fade-transition">
                   Usuario registrado
                  </v-alert>
                </div>
                <div v-if="error">
                <v-alert type="error" :value="error" class="text-left" transition="fade-transition">
                  {{text_error}}
                </v-alert>
               </div>
              
                <v-btn color="#1565C0" block type='submit' dark transition="fade-transition">
                  Registrar usuario
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols=6>
          <v-card flat height="100%">
            <v-card-title >
              <h3 class="font-weight-light">Usuarios</h3>
            </v-card-title>
            <v-card-text class="mt-4">
              <h1 class="font-weight-medium">Test</h1>
              <h2 class="font-weight-light mt-2">Cantidad de días: 5</h2>
              <h2 class="font-weight-light mt-2">Cantidad de usuarios: <b>{{cantidad['Test']}}</b></h2>

              <h1 class="font-weight-medium mt-8">Demo</h1>
              <h2 class="font-weight-light mt-2">Cantidad de días: 15</h2>
              <h2 class="font-weight-light mt-2">Cantidad de usuarios: <b>{{cantidad['Demo']}}</b></h2>

              <h1 class="font-weight-medium mt-8">Prueba</h1>
              <h2 class="font-weight-light mt-2">Cantidad de días: 30</h2>
              <h2 class="font-weight-light mt-2">Cantidad de usuarios: <b>{{cantidad['Prueba']}}</b></h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

	</div>
</template>

<script>
import axios from 'axios';

export default {

  name: 'admin',

  data () {
    return {
      cantidad: {'Test': 0,'Demo':0, 'Prueba': 0},
      email: null,
      tipo_usuario: null,
      tipos_usuarios: ['Test','Demo','Prueba'],
      password: null,
      username: null,
      register_succesful: false,
      error: false,
      text_error:null
    }
  },
  mounted(){
    this.get_data_test();
    this.get_data_demo();
    this.get_data_prueba();
  },
  computed: {
    is_staff: function(){
      let x = localStorage.getItem('is_staff');
      return x
    },
  },
  methods: {
    async get_data_test(){
      let response = await axios.get("https://api.datari.net/auth/users/?user_type=test");
      this.cantidad['Test'] = response.data.count;
    },

    async get_data_demo(){
      let response = await axios.get("https://api.datari.net/auth/users/?user_type=demo");
      this.cantidad['Demo'] = response.data.count;
    },

    async get_data_prueba(){
      let response = await axios.get("https://api.datari.net/auth/users/?user_type=prueba");
      this.cantidad['Prueba'] = response.data.count;
    },
    register(){
       if(this.email != null &&  this.password != null){
        let email = this.email;
        let password = this.password;
        let username = this.username;
        let user_type = this.tipo_usuario.toLowerCase();
        this.$refs.form.validate();
        this.$store.dispatch('register', {email, username,password,user_type})
        .then( () => {
          this.error = false;
          this.register_succesful = true;
          this.sleep(1000).then(() => {
            this.get_data_test();
            this.get_data_demo();
            this.get_data_prueba();
          });
        })
        .catch(err => {
          let type_error = err.response.data.errors.email[0];
          this.text_error = type_error;
          this.error = true;
          this.register_succesful = false;
         
          // if(type_error === 'credenciales_invalidas'){
          //   this.error_credenciales=true;
          //   this.error_active = false;
          // }
          // else if(type_error === 'desactivada'){
          //   this.error_credenciales = false;
          //   this.error_active = true;
          // }
        })
      }
    },
    cambiarTipo(tipo){
      this.tipo_usuario = tipo;
      this.username = this.tipo_usuario+'_'+(this.cantidad[this.tipo_usuario]+1);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }
}
</script>

<style lang="css" scoped>
</style>